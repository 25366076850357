var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            [
              _c(
                "v-toolbar",
                { attrs: { flat: "", rounded: "" } },
                [
                  _c(
                    "v-dialog",
                    {
                      ref: "endDateDialog",
                      attrs: { persistent: "", width: "290px" },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function ({ on, attrs }) {
                            return [
                              _c(
                                "v-text-field",
                                _vm._g(
                                  _vm._b(
                                    {
                                      staticStyle: { "max-width": "300px" },
                                      attrs: {
                                        "prepend-icon": "mdi-calendar",
                                        readonly: "",
                                        label: "Kontrol Tarihi",
                                        "hide-details": "",
                                        outlined: "",
                                        dense: "",
                                        "append-icon": "mdi-close-circle",
                                      },
                                      on: {
                                        "click:append": function ($event) {
                                          _vm.filters.endDates = []
                                          _vm.getData()
                                        },
                                      },
                                      model: {
                                        value: _vm.endDateText,
                                        callback: function ($$v) {
                                          _vm.endDateText = $$v
                                        },
                                        expression: "endDateText",
                                      },
                                    },
                                    "v-text-field",
                                    attrs,
                                    false
                                  ),
                                  on
                                )
                              ),
                            ]
                          },
                        },
                      ]),
                      model: {
                        value: _vm.endDateDialog,
                        callback: function ($$v) {
                          _vm.endDateDialog = $$v
                        },
                        expression: "endDateDialog",
                      },
                    },
                    [
                      _c(
                        "v-card",
                        [
                          _c(
                            "v-date-picker",
                            {
                              attrs: {
                                "first-day-of-week": "1",
                                scrollable: "",
                                range: "",
                                locale: "tr",
                                color: "green lighten-1",
                                "header-color": "primary",
                                min: "2023-07-01",
                              },
                              model: {
                                value: _vm.filters.endDates,
                                callback: function ($$v) {
                                  _vm.$set(_vm.filters, "endDates", $$v)
                                },
                                expression: "filters.endDates",
                              },
                            },
                            [
                              _c("v-spacer"),
                              _c(
                                "v-btn",
                                {
                                  attrs: { text: "", color: "primary" },
                                  on: {
                                    click: function ($event) {
                                      _vm.endDateDialog = false
                                    },
                                  },
                                },
                                [_vm._v(" İPTAL")]
                              ),
                              _c(
                                "v-btn",
                                {
                                  attrs: { text: "", color: "primary" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.fixDates("endDate")
                                    },
                                  },
                                },
                                [_vm._v(" ARA")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("v-divider", {
                    staticClass: "mx-2",
                    attrs: { inset: "", vertical: "" },
                  }),
                  _c(
                    "v-dialog",
                    {
                      ref: "startDateDialog",
                      attrs: { persistent: "", width: "290px" },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function ({ on, attrs }) {
                            return [
                              _c(
                                "v-text-field",
                                _vm._g(
                                  _vm._b(
                                    {
                                      staticStyle: { "max-width": "300px" },
                                      attrs: {
                                        "prepend-icon": "mdi-calendar",
                                        readonly: "",
                                        label: "Odev Verilme Tarihi",
                                        "hide-details": "",
                                        outlined: "",
                                        dense: "",
                                        "append-icon": "mdi-close-circle",
                                      },
                                      on: {
                                        "click:append": function ($event) {
                                          _vm.filters.startDates = []
                                          _vm.getData()
                                        },
                                      },
                                      model: {
                                        value: _vm.startDateText,
                                        callback: function ($$v) {
                                          _vm.startDateText = $$v
                                        },
                                        expression: "startDateText",
                                      },
                                    },
                                    "v-text-field",
                                    attrs,
                                    false
                                  ),
                                  on
                                )
                              ),
                            ]
                          },
                        },
                      ]),
                      model: {
                        value: _vm.startDateDialog,
                        callback: function ($$v) {
                          _vm.startDateDialog = $$v
                        },
                        expression: "startDateDialog",
                      },
                    },
                    [
                      _c(
                        "v-card",
                        [
                          _c(
                            "v-date-picker",
                            {
                              attrs: {
                                "first-day-of-week": "1",
                                scrollable: "",
                                range: "",
                                locale: "tr",
                                color: "green lighten-1",
                                "header-color": "primary",
                                min: "2023-07-01",
                              },
                              model: {
                                value: _vm.filters.startDates,
                                callback: function ($$v) {
                                  _vm.$set(_vm.filters, "startDates", $$v)
                                },
                                expression: "filters.startDates",
                              },
                            },
                            [
                              _c("v-spacer"),
                              _c(
                                "v-btn",
                                {
                                  attrs: { text: "", color: "primary" },
                                  on: {
                                    click: function ($event) {
                                      _vm.startDateDialog = false
                                    },
                                  },
                                },
                                [_vm._v(" İPTAL")]
                              ),
                              _c(
                                "v-btn",
                                {
                                  attrs: { text: "", color: "primary" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.fixDates("startDate")
                                    },
                                  },
                                },
                                [_vm._v(" ARA")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("v-divider", {
                    staticClass: "mx-2",
                    attrs: { inset: "", vertical: "" },
                  }),
                  _c(
                    "v-dialog",
                    {
                      ref: "planningDateDialog",
                      attrs: { persistent: "", width: "290px" },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function ({ on, attrs }) {
                            return [
                              _c(
                                "v-text-field",
                                _vm._g(
                                  _vm._b(
                                    {
                                      staticStyle: { "max-width": "300px" },
                                      attrs: {
                                        "prepend-icon": "mdi-calendar",
                                        readonly: "",
                                        label: "Planlama Tarihi",
                                        "hide-details": "",
                                        outlined: "",
                                        dense: "",
                                        "append-icon": "mdi-close-circle",
                                      },
                                      on: {
                                        "click:append": function ($event) {
                                          _vm.filters.planningDates = []
                                          _vm.getData()
                                        },
                                      },
                                      model: {
                                        value: _vm.planningDateText,
                                        callback: function ($$v) {
                                          _vm.planningDateText = $$v
                                        },
                                        expression: "planningDateText",
                                      },
                                    },
                                    "v-text-field",
                                    attrs,
                                    false
                                  ),
                                  on
                                )
                              ),
                            ]
                          },
                        },
                      ]),
                      model: {
                        value: _vm.planningDateDialog,
                        callback: function ($$v) {
                          _vm.planningDateDialog = $$v
                        },
                        expression: "planningDateDialog",
                      },
                    },
                    [
                      _c(
                        "v-card",
                        [
                          _c(
                            "v-date-picker",
                            {
                              attrs: {
                                "first-day-of-week": "1",
                                scrollable: "",
                                range: "",
                                locale: "tr",
                                color: "green lighten-1",
                                "header-color": "primary",
                                min: "2023-10-01",
                              },
                              model: {
                                value: _vm.filters.planningDates,
                                callback: function ($$v) {
                                  _vm.$set(_vm.filters, "planningDates", $$v)
                                },
                                expression: "filters.planningDates",
                              },
                            },
                            [
                              _c("v-spacer"),
                              _c(
                                "v-btn",
                                {
                                  attrs: { text: "", color: "primary" },
                                  on: {
                                    click: function ($event) {
                                      _vm.planningDateDialog = false
                                    },
                                  },
                                },
                                [_vm._v(" İPTAL")]
                              ),
                              _c(
                                "v-btn",
                                {
                                  attrs: { text: "", color: "primary" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.fixDates("planningDate")
                                    },
                                  },
                                },
                                [_vm._v(" ARA")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("v-divider", {
                    staticClass: "mx-2",
                    attrs: { inset: "", vertical: "" },
                  }),
                  _c("c-lesson", {
                    on: { change: _vm.getData },
                    model: {
                      value: _vm.filters.lessonId,
                      callback: function ($$v) {
                        _vm.$set(_vm.filters, "lessonId", $$v)
                      },
                      expression: "filters.lessonId",
                    },
                  }),
                  _c("v-divider", {
                    staticClass: "mx-2",
                    attrs: { inset: "", vertical: "" },
                  }),
                  _c("v-select", {
                    staticStyle: { "max-width": "200px" },
                    attrs: {
                      items: _vm.homeworkStatuses,
                      outlined: "",
                      dense: "",
                      label: "Ödev Durumu",
                      "hide-details": "",
                    },
                    on: { change: _vm.getData },
                    model: {
                      value: _vm.filters.homeworkStatus,
                      callback: function ($$v) {
                        _vm.$set(_vm.filters, "homeworkStatus", $$v)
                      },
                      expression: "filters.homeworkStatus",
                    },
                  }),
                  _c("v-spacer"),
                  _vm.$route.query.testIds != null
                    ? _c(
                        "v-btn",
                        {
                          attrs: { color: "warning", text: "" },
                          on: { click: _vm.clearFilters },
                        },
                        [_vm._v("YENİLE")]
                      )
                    : _vm._e(),
                  _c(
                    "v-btn",
                    {
                      staticClass: "ml-2",
                      attrs: {
                        color: "primary",
                        to: { name: "calisma-planim" },
                      },
                    },
                    [_vm._v("Çalışma Planım")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            [
              _c(
                "v-card",
                [
                  _c(
                    "v-card-text",
                    [
                      _c("v-data-table", {
                        attrs: {
                          headers: _vm.headers,
                          items: _vm.rows,
                          search: _vm.filters.search,
                          options: _vm.options,
                          "server-items-length": _vm.totalRows,
                          loading: _vm.loading,
                          "multi-sort": "",
                          "sort-by": _vm.options.sortBy,
                          "sort-desc": _vm.options.sortDesc,
                          "disable-pagination": "",
                          "hide-default-footer": "",
                          "item-key": "HomeworkId",
                          "show-select": "",
                          "fixed-header": "",
                          "mobile-breakpoint": "0",
                        },
                        on: {
                          "update:options": function ($event) {
                            _vm.options = $event
                          },
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: `item.EndDate`,
                              fn: function ({ item }) {
                                return [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm._f("formattedDate")(item.EndDate)
                                      ) +
                                      " "
                                  ),
                                ]
                              },
                            },
                            {
                              key: `item.PlannedDate`,
                              fn: function ({ item }) {
                                return [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm._f("formattedDate")(
                                          item.PlannedDate
                                        )
                                      ) +
                                      " "
                                  ),
                                ]
                              },
                            },
                            {
                              key: `item.DoneDate`,
                              fn: function ({ item }) {
                                return [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm._f("formattedDate")(item.DoneDate)
                                      ) +
                                      " "
                                  ),
                                ]
                              },
                            },
                            {
                              key: `item.StartDate`,
                              fn: function ({ item }) {
                                return [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm._f("formattedDate")(item.StartDate)
                                      ) +
                                      " "
                                  ),
                                ]
                              },
                            },
                            {
                              key: `item.Status`,
                              fn: function ({ item }) {
                                return [
                                  item.Status
                                    ? _c("span", [
                                        _c(
                                          "span",
                                          {
                                            staticStyle: {
                                              color: "forestgreen",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(item.CorrectCount) + "D"
                                            ),
                                          ]
                                        ),
                                        _vm._v(" / "),
                                        _c(
                                          "span",
                                          { staticStyle: { color: "red" } },
                                          [
                                            _vm._v(
                                              _vm._s(item.WrongCount) + "Y"
                                            ),
                                          ]
                                        ),
                                        _vm._v(" / "),
                                        _c(
                                          "span",
                                          { staticStyle: { color: "grey" } },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(item.EmptyCount) +
                                                "B "
                                            ),
                                          ]
                                        ),
                                      ])
                                    : _c(
                                        "span",
                                        { staticStyle: { color: "red" } },
                                        [_vm._v("YAPMADI")]
                                      ),
                                ]
                              },
                            },
                            {
                              key: `item.ClassStatus`,
                              fn: function ({ item }) {
                                return [
                                  _c(
                                    "v-progress-linear",
                                    {
                                      attrs: {
                                        rounded: "",
                                        value:
                                          item.CompletedStudentCount > 0
                                            ? (item.CompletedStudentCount /
                                                item.StudentCount) *
                                              100
                                            : 0,
                                        color: _vm.getColor(item),
                                        height: "25",
                                      },
                                    },
                                    [
                                      _c("strong", [
                                        _vm._v(
                                          _vm._s(item.CompletedStudentCount) +
                                            "/" +
                                            _vm._s(item.StudentCount)
                                        ),
                                      ]),
                                    ]
                                  ),
                                ]
                              },
                            },
                            {
                              key: `item.Actions`,
                              fn: function ({ item }) {
                                return [
                                  !item.IsLocked
                                    ? _c(
                                        "v-btn",
                                        {
                                          attrs: { icon: "", small: "" },
                                          on: {
                                            click: function ($event) {
                                              $event.stopPropagation()
                                              return _vm.openHomeworkAnswerDialog(
                                                item
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("v-icon", [
                                            _vm._v("mdi-square-edit-outline"),
                                          ]),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ]
                              },
                            },
                          ],
                          null,
                          true
                        ),
                        model: {
                          value: _vm.selectedHomeworks,
                          callback: function ($$v) {
                            _vm.selectedHomeworks = $$v
                          },
                          expression: "selectedHomeworks",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-card-actions",
                    { staticClass: "mt-5" },
                    [
                      _c(
                        "v-row",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.rows.length > 0,
                              expression: "rows.length > 0",
                            },
                          ],
                        },
                        [
                          _c(
                            "v-col",
                            [
                              _c(
                                "v-chip",
                                {
                                  staticClass: "m-2",
                                  attrs: { color: "primary", label: "" },
                                },
                                [
                                  _vm._v(
                                    "Toplam Test Sayısı: " +
                                      _vm._s(_vm.rows.length)
                                  ),
                                ]
                              ),
                              _c(
                                "v-chip",
                                {
                                  staticClass: "m-2",
                                  attrs: { color: "primary", label: "" },
                                },
                                [
                                  _vm._v(
                                    "Toplam Soru Sayısı: " +
                                      _vm._s(
                                        _vm.rows.reduce(
                                          (a, b) => a + b.QuestionCount,
                                          0
                                        )
                                      )
                                  ),
                                ]
                              ),
                              _vm.selectedHomeworks.length > 0
                                ? _c(
                                    "v-chip",
                                    {
                                      staticClass: "m-2",
                                      attrs: { color: "success", label: "" },
                                    },
                                    [
                                      _vm._v(
                                        "Seçilen Test Sayısı: " +
                                          _vm._s(_vm.selectedHomeworks.length)
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _vm.selectedHomeworks.length > 0
                                ? _c(
                                    "v-chip",
                                    {
                                      staticClass: "m-2",
                                      attrs: { color: "success", label: "" },
                                    },
                                    [
                                      _vm._v(
                                        "Seçilen Soru Sayısı: " +
                                          _vm._s(
                                            _vm.selectedHomeworks.reduce(
                                              (a, b) => a + b.QuestionCount,
                                              0
                                            )
                                          )
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            [
              _vm.selectedHomeworks.length > 0
                ? _c(
                    "v-toolbar",
                    { attrs: { flat: "", rounded: "" } },
                    [
                      _c(
                        "v-chip-group",
                        {
                          staticStyle: { "max-width": "700px" },
                          attrs: {
                            "active-class": "deep-purple--text text--accent-4",
                            "show-arrows": "",
                          },
                          model: {
                            value: _vm.editedItem.PlannedDate,
                            callback: function ($$v) {
                              _vm.$set(_vm.editedItem, "PlannedDate", $$v)
                            },
                            expression: "editedItem.PlannedDate",
                          },
                        },
                        _vm._l(_vm.dates, function (date) {
                          return _c(
                            "v-chip",
                            { key: date, attrs: { value: date } },
                            [_vm._v(" " + _vm._s(_vm.showDay(date)) + " ")]
                          )
                        }),
                        1
                      ),
                      _c("c-date-picker", {
                        key: _vm.editedItem.PlannedDate,
                        attrs: { "hide-details": "", label: "Planlanan Tarih" },
                        model: {
                          value: _vm.editedItem.PlannedDate,
                          callback: function ($$v) {
                            _vm.$set(_vm.editedItem, "PlannedDate", $$v)
                          },
                          expression: "editedItem.PlannedDate",
                        },
                      }),
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            color: "primary",
                            text: "",
                            loading: _vm.saving,
                            large: "",
                          },
                          on: { click: _vm.save },
                        },
                        [_vm._v("Çalışma Planıma Ekle")]
                      ),
                    ],
                    1
                  )
                : _c("v-alert", { attrs: { type: "info", text: "" } }, [
                    _vm._v("Planlama yapmak icin ödev seçiniz."),
                  ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "500" },
          model: {
            value: _vm.homeworkAnswerDialog,
            callback: function ($$v) {
              _vm.homeworkAnswerDialog = $$v
            },
            expression: "homeworkAnswerDialog",
          },
        },
        [
          _c(
            "v-card",
            { attrs: { flat: "" } },
            [
              _c("v-card-subtitle"),
              _c(
                "v-card-text",
                [
                  _vm.homeworkAnswerDialog
                    ? _c("odev-cevaplari", {
                        attrs: {
                          "test-id": _vm.answerItem.SourceTestId,
                          "hw-id": _vm.answerItem.HomeworkId,
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-fab-transition",
        [
          _c(
            "v-btn",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.selectedHomeworks.length > 0,
                  expression: "selectedHomeworks.length > 0",
                },
              ],
              attrs: {
                color: "primary",
                bottom: "",
                right: "",
                fixed: "",
                "x-large": "",
              },
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  _vm.planDialog = !_vm.planDialog
                },
              },
            },
            [
              _c(
                "v-chip",
                {
                  staticClass: "mr-1",
                  attrs: {
                    color: "success",
                    label: "",
                    outlined: "",
                    "text-color": "white",
                  },
                },
                [_vm._v(" " + _vm._s(_vm.selectedHomeworks.length) + " Test")]
              ),
              _c(
                "v-chip",
                {
                  attrs: {
                    color: "success",
                    label: "",
                    outlined: "",
                    "text-color": "white",
                  },
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.selectedHomeworks.reduce(
                          (a, b) => a + b.QuestionCount,
                          0
                        )
                      ) +
                      " Soru"
                  ),
                ]
              ),
              _c("v-icon", { staticClass: "ml-2", attrs: { small: "" } }, [
                _vm._v("mdi-calendar"),
              ]),
              _c(
                "span",
                {
                  staticClass: "ml-1",
                  staticStyle: { "font-size": "x-small" },
                },
                [_vm._v(" Plana Ekle ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          ref: "menu",
          attrs: {
            "return-value": _vm.editedItem.PlannedDate,
            transition: "scale-transition",
            "offset-y": "",
            "min-width": "auto",
            "max-width": "400",
          },
          on: {
            "update:returnValue": function ($event) {
              return _vm.$set(_vm.editedItem, "PlannedDate", $event)
            },
            "update:return-value": function ($event) {
              return _vm.$set(_vm.editedItem, "PlannedDate", $event)
            },
          },
          model: {
            value: _vm.planDialog,
            callback: function ($$v) {
              _vm.planDialog = $$v
            },
            expression: "planDialog",
          },
        },
        [
          _c(
            "v-date-picker",
            {
              attrs: { scrollable: "", "full-width": "" },
              model: {
                value: _vm.editedItem.PlannedDate,
                callback: function ($$v) {
                  _vm.$set(_vm.editedItem, "PlannedDate", $$v)
                },
                expression: "editedItem.PlannedDate",
              },
            },
            [
              _c(
                "v-btn",
                {
                  attrs: { text: "", color: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.planDialog = false
                    },
                  },
                },
                [_vm._v(" Vazgeç ")]
              ),
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { text: "", color: "primary" },
                  on: { click: _vm.save },
                },
                [_vm._v(" Çalışma Planıma Ekle ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }